import React from "react";
import { graphql } from "gatsby";
import { Provider } from "react-redux";
import store from "../coms/data/store";
import Container from "@mui/material/Container";

import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import Seo from "../coms/global/Seo";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Provider store={store}>
      <Seo title={frontmatter.title} pathname={frontmatter.slug} />
      <MainFrame>
        <Layout>
          <Container maxWidth="md" sx={{ padding: "50px 10px" }}>
            <div className="blog-post">
              <h1>{frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </Container>
        </Layout>
      </MainFrame>
    </Provider>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        slug
        title
      }
    }
  }
`;
